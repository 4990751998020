<template>
  <div>
    <div>
      <rxNavBar :title="title"></rxNavBar>
    </div>
    <img class="nav" src="../../../assets/images/InviteFriends-navImg.png" @click="leftReturn" >
    <div id="url">
      <img :src="url"  style="margin-top: 2.3rem">
    </div>
    <common-empty v-if="!url"></common-empty>
  </div>
</template>

<script>
  import {getResetTariff, queryBaseData} from "../../../getData/getData";
  import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import {checkAndroid, checkIOS, responseUtil} from "../../../libs/rongxunUtil";
  import {List, NavBar, Popup, PullRefresh} from "vant";

export default {
  name: "resetTariff",
  components:{
    rxNavBar,
  },
  data() {
    return {
      url: '',
      title: '充值电费'
    }
  },
  created() {
    this.initDicData()
  },
  methods: {
    //初始化字典数据
    //初始化字典数据
    initDicData: function () {
      const that = this
      let initData = {}
      initData.dbName = ["rechargeElectricCharge"]
      queryBaseData(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          debugger
          if(response.data.data.rechargeElectricCharge[0].dictionaryValue)
            that.url = response.data.data.rechargeElectricCharge[0].dictionaryValue
            that.title = response.data.data.rechargeElectricCharge[0].dictionaryTitle
        })
      })
    },
    leftReturn(){
        this.$router.go(-1);
    },
  }
}
</script>

<style scoped>
.nav{
  left: 10px;
  position: fixed;
  z-index: 1;
  height: 20px;
  top: 10px;
}
#url{
  width: 100%;
  height: auto;
  /*overflow:hidden;*/
}
#url img{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  overflow-x: hidden;
  display: block;
  min-width: 100%;
  min-height: 100%;
  transform:translate(-50%,-50%);
}
</style>
